@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Light.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Regular.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Semibold.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Extrabold.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Extrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Thin.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Medium.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Banana Grotesk';
    src: url('/fonts/BananaGrotesk-Extralight.woff2') format('woff2'),
    url('/fonts/BananaGrotesk-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Migra';
    src: url('/fonts/PPMigraItalic-Italic.woff2') format('woff2'),
    url('/fonts/PPMigraItalic-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PP Migra';
    src: url('/fonts/PPMigra-Regular.woff2') format('woff2'),
    url('/fonts/PPMigra-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


